import React from "react";
import { Row, Col, Button, Radio, Input, notification } from 'antd';
import { useControl } from '@essenza/react';
import { ReactComponent as FrecciaBianca } from '../assets/svg/freccia-bianca.svg';
import { LessonModel } from "../models/lessonmodel";

const { TextArea } = Input;

function QuestionnaireViewController(c) {
    c.skin = QuestionnaireView;

    c.command = {
        SEND_ANSWER: (item, { model }) => {
            model.request(LessonModel, m => m.answer(item));
        }
    }
}

export function QuestionnaireView({ source, label, npath, ndata }) {
    const [control] = useControl(QuestionnaireViewController);

    const data = source.data;

    const questions = source.data?.questions

    return (
        <div style={{ width: '900px', marginTop: '24px' }} className="layout-form centered">
            <Row gutter={8}>
                <Col flex="auto"><h2>{data.tname}</h2></Col>
                <Col>
                    <Button className='secondaryButton' onClick={() => control.navigate(-1, source.id)}>
                        Indietro
                    </Button>
                </Col>
                <Col>
                    <Button className='secondaryButton' onClick={() => {
                        notification.success({ message: "Questionario inviato con successo." });
                        control.navigate(-1, source.id);
                    }}>
                        Invia
                    </Button>
                </Col>
            </Row>

            <h3>{data.tdescription}</h3>
            {
                questions && questions.map(question => {
                    if (!question) return null;
                    return <div style={{ width: '900px', marginTop: '24px' }} className="single-section-custom centered">
                        {
                            question.kind === 0 ? <AnswerMultiple source={question} /> : <AnswerFree source={question} />
                        }
                    </div>
                })
            }

        </div>
    )
}

export function AnswerFree({ source }) {
    const [control] = useControl(QuestionnaireViewController);
    return (
        <>
            <h3>{source.title}</h3>
            <p className="mb-sm">{source.description}</p>
            <TextArea rows={4} onBlur={(e) => control.execute("SEND_ANSWER", { id: source.id, text: e.target.value, value: 0 })} />
        </>
    )
}

export function AnswerMultiple({ source }) {
    const [control] = useControl(QuestionnaireViewController);
    const values = source.options;
    const onChange = (e) => {
        const data = e.target.value;
        control.execute("SEND_ANSWER", { id: source.id, text: data.title, value: data.value })
        console.log('radio checked', e.target.value);
    };
    return (
        <>
            <h3>{source.title}</h3>
            <p className="mb-sm">{source.description}</p>
            <Radio.Group onChange={onChange} >
                {
                    values && values.map(value => value ? <>
                        <Radio value={value}>{value.title}</Radio>
                    </> : null)
                }
            </Radio.Group>
        </>
    )
}