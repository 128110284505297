import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Vista, useModel, useVista, useGraph } from "@essenza/react";
import { LessonModel } from "../models/lessonmodel";
import { QuestionnaireView } from "../view/questionnaire_view";

function QuestionnaireVistaController(c) {
    c.skin = QuestionnaireVista;
}

export function QuestionnaireVista() {
    const [ctx] = useVista(QuestionnaireVistaController);
    const lesson = useGraph(LessonModel, "detail");
    const [model] = useModel();
    const id = useLocation().state;
    console.log("QUESTIONNAIRE STATE ID", id);
    useEffect(() => {
        console.log("MODEL ", model);
        if (model) {
            model.read(LessonModel, m => m.detail(id)); //condition state = 'P' (published)
        }
    }, [model, id]);

    if(!lesson?.data) return null;

    return (
        <Vista context={ctx} >
            <div className='content-max-width'>
                <QuestionnaireView source={lesson} />
            </div>
        </Vista>
    )
}