import { Col, List, Progress, Row } from "antd";
import { useControl } from "@essenza/react"
import { PlayCircleFilled } from '@ant-design/icons';


function LessonListController(c) {
    c.skin = LessonList;
    c.command = {
        DETAIL: (item) => {
            console.log("LESSON STATE ID", item)
            c.navigate(item.kind ? "questionario" : "lessondetail", { state: item.id });
        }
    }
}

export function LessonList({ source, currentid }) {
    const [control] = useControl(LessonListController);
    console.log("LESSON LIST", source);
    const format = (item) => {
        if (item.id === currentid)
            return 'In Corso'
        else if (item.state === 'C')
            return 'Completata'
        else if (item.state === 'P')
            return (<><Progress percent={item.progress} size="small" /></>);
        else return '';
    }
    return (
        <>
            <div className="" style={{ padding: '24px', backgroundColor: 'white' }}>
                <List dataSource={source.getCollection()} renderItem={item => (
                    <List.Item>
                        <Row style={{ width: '100%' }} onClick={() => control.execute("DETAIL", item)} className="h6 pointer">
                            <Col flex="none" className={item.id === currentid ? "csquare" : "square"} >{item.iorder + '.'}</Col>
                            <Col flex="none" style={{ paddingTop: "4px", paddingLeft: "16px" }} >{item.kind ? "Questionario: " : <PlayCircleFilled />}</Col>
                            <Col flex="auto" style={{ paddingTop: "4px", paddingLeft: '16px' }} >{item.tname}</Col>

                            <Col flex="80px" style={{ paddingTop: "4px" }} className="h7" >{format(item)}</Col>
                        </Row>
                    </List.Item>
                )}
                />
            </div>
        </>
    )
}